/* ============ FONTS ============ */
@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&Roboto:wght@100;400;700&display=swap";

@mixin primary-font() {
  font-family: Roboto, sans-serif;

  // font-family: "Open Sans", sans-serif;
}

@mixin secondary-font() {
  font-family: Roboto, sans-serif;
}

/* ============ COLORS ============ */
$base-color-1: #343d46;
$base-color-2: #a7adba;
$base-color-3: #4f5b66;
$base-color-4: #fcfcfc;
$base-color-5: #d9c0d9;
$positive-color: #66af48;
$light-positive-color: #c3e2b3;
$dark-positive-color: #58943F;
$neutral-color: #46a8cb;
$light-neutral-color: #bfe2ee;
$dark-neutral-color: #35748A;
$negative-color: #d33372;
$light-negative-color: #f0b3cd;
$dark-negative-color: #8F2951;
$gokart-yellow: #fef058;
$smiley-positive: #66af48;
$smiley-neutral: #fac805;
$smiley-negative: #d33372;
$button-light-text-color: white;
$button-dark-text-color: black;
$disabled-background: $base-color-2;
$disabled-foreground: $base-color-4;

$dark_mode_light: white;
$dark_mode_dark: rgb(30 30 32);
$dark_mode_darkest: #181818;


/* VARIABLES */
$breakpoint-xs: 320; //576px;
$breakpoint-s: 768;
$breakpoint-m: 992;
$breakpoint-l: 1200;
$breakpoint-xl: 1400;
$font-start-size: 14px;
$font-end-size: 15px;
$section-max-width: 530px;

/* STYLEGUIDE SPECIFIC RULES - NOT USED IN APPLICATION */

body,
html {
  margin: 0;
  background: white;
  overflow-x: hidden;
}

.styleguide-chapter {
  @include primary-font;

  padding: 30px 10px;
  position: relative;

  &::before {
    content: "";
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    background-image: repeating-linear-gradient(
      to right,
      black 0%,
      black 50%,
      white 50%,
      white 100%
    );
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;
  }

  &:first-child::before {
    background-image: none;
  }

  .styleguide-heading {
    font-size: 30px;
  }

  .swatch {
    background: black;
    color: white;
  }

  .swatches {
    display: flex;
    flex-wrap: wrap;

    .swatch {
      padding: 15px 30px;
      border-radius: 15px;
      margin: 10px;
      flex-grow: 1;
      text-align: center;
      color: black;

      &.white {
        color: white;
      }

      &.transparent {
        //color: black;
        background: transparent;
      }
    }
  }
}

/* ============ APPLICATION CSS ============ */

.procommerca, .funbutler {
  font-size: 10px;

  @include primary-font;


  @media screen and (min-width: #{$breakpoint-xs}px) {
    font-size: calc(
      #{$font-start-size} + 6 * ((100vw - #{$breakpoint-xs}px) / #{$breakpoint-xs *
            2})
    );
  }

  @media screen and (min-width: #{$breakpoint-m}px) {
    &:not(.embed-mode){
    font-size: $font-end-size;
    }
  }

  @media screen and (min-width: #{$breakpoint-s}px) {
    &.embed-mode{
    font-size: $font-end-size;
    }
  }

  /* PRINT */
    .print-show {
    visibility: collapse !important;
    height: 0;
  }

    @media print {
    .print-hide {
      visibility: collapse !important;
      display: none !important;
    }

    .print-show {
      visibility: visible !important;
      height: auto;
    }
  }

  /* UTILITY RULES */
  .clickable {
    cursor: pointer !important;
  }

    .not-clickable {
    cursor: default !important;
  }

  .fullwidth {
    width: 100%;
  }

    .fullheight {
    height: 100%;
  }

  .align-right {
    text-align: right;
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .valign-top{
    vertical-align:top;
  }

    .valign-bottom{
    vertical-align:bottom;
  }

    .valign-middle{
     vertical-align:middle;
  }

  .no-padding {
    padding: 0;
  }

  label {
    font-weight: normal;
  }

  img {
    max-width: 100%;
  }

  .transparent-background{
   background:transparent;
    }

  i {
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    &.spinning {
      transform-origin: center;
      animation: spin 2s linear infinite;
    }
  }

  a {
    color: $negative-color;
  }

  /* FONTS AND TEXT */
  .primary-font {
    @include primary-font;
  }

  .secondary-font {
    @include secondary-font;
  }

    .capitalize {
    text-transform: capitalize;
  }

      .uppercase {
    text-transform: uppercase;
  }

      .lowercase {
    text-transform: lowercase;
  }

  .italic-text {
    font-style: italic;
  }

  .light-text {
    font-weight: 200;
  }

  .regular-text {
    font-weight: 400;
  }

  .bold-text {
    font-weight: 700;
  }

  .small-text {
    font-size: 0.8em;
    line-height: 1.2em;
  }

  .normal-text {
    font-size: 1em;
    line-height: 1.2em;
  }

  .large-text {
    font-size: 1.333em;
    line-height: 1.2em;
  }

  .extra-large-text {
    font-size: 1.467em;
    line-height: 1.2em;
  }

  .heading-1,
  .heading-2,
  .heading-3,
  .heading-4 {
    font-weight: normal;
    margin-bottom: 0.2em;
    color: $base-color-2;

    @include secondary-font;

    &.bold-text{
      font-weight:700;
    }
  }

  .heading-1 {
    font-size: 2em;
  }

  .heading-2 {
    font-size: 1.6em;
  }

  .heading-3 {
    font-size: 1.333em;
  }

  .heading-4 {
    font-size: 1em;
  }

  /* COLORS */
  .base-background-1 {
    background: $base-color-1;
  }

  .base-background-2 {
    background: $base-color-2;
  }

  .base-background-3 {
    background: $base-color-3;
  }

  .base-background-4 {
    background: $base-color-4;
  }

  .base-background-5 {
    background: $base-color-5;
  }

  .positive-background {
    background: $positive-color;
  }

  .light-positive-background {
    background: $light-positive-color;
  }

  .neutral-background {
    background: $neutral-color;
  }

  .light-neutral-background {
    background: $light-neutral-color;
  }

  .negative-background {
    background: $negative-color;
  }

  .light-negative-background {
    background: $light-negative-color;
  }

  .gokart-yellow-background {
    background: $gokart-yellow;
  }

  .primary-background {
    background: $base-color-4;
  }

    .smiley-positive-background {
    background: $smiley-positive;
  }

      .smiley-neutral-background {
    background: $smiley-neutral;
  }

      .smiley-negative-background {
    background: $smiley-negative;
  }

  .base-foreground-1 {
    color: $base-color-1;
  }

  .base-foreground-2 {
    color: $base-color-2;
  }

  .base-foreground-3 {
    color: $base-color-3;
  }

  .base-foreground-4 {
    color: $base-color-4;
  }

  .base-foreground-5 {
    color: $base-color-5;
  }

  .positive-foreground {
    color: $positive-color;
  }

  .light-positive-foreground {
    color: $light-positive-color;
  }

  .neutral-foreground {
    color: $neutral-color;
  }

  .light-neutral-foreground {
    color: $light-neutral-color;
  }

  .negative-foreground {
    color: $negative-color;
  }

  .light-negative-foreground {
    color: $light-negative-color;
  }

  .gokart-yellow-foreground {
    color: $gokart-yellow;
  }

      .smiley-positive-foreground {
    color: $smiley-positive;
  }

      .smiley-neutral-foreground {
    color: $smiley-neutral;
  }

      .smiley-negative-foreground {
    color: $smiley-negative;
  }

  .dark-mode-visible {
      display: none;
   }

  /* DARK MODE */
  &.dark-mode, .dark-mode {
    .dark-mode-light-color {
      color: $dark_mode_light !important;

      th,
      td {
        color: $dark_mode_light !important;
      }
    }

    .torn {
      &::before, &::after {
        background: $dark_mode_dark !important;
      }
    }

    .dark-mode-dark-color {
      color: $dark_mode_dark !important;

      th,
      td {
        color: $dark_mode_dark !important;
      }
    }


    .dark-mode-light-background {
      background: $dark_mode_light !important;
    }

    &.dark-mode-dark-background {
      background: $dark_mode_dark !important;

      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .input-wrapper > label,
      .mat-select-value,
      .mat-select-arrow {
        background: $dark_mode_dark !important;
        color: $dark_mode_light !important;
      }
    }

    .dark-mode-dark-background {
      background: $dark_mode_dark !important;

      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .input-wrapper > label,
      .mat-select-value,
      .mat-select-arrow {
        background: $dark_mode_dark !important;
        color: $dark_mode_light !important;
      }
    }

    .dark-mode-darkest-background {
      background: $dark_mode_darkest;

      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .input-wrapper > label,
      .mat-select-value,
      .mat-select-arrow {
        background: $dark_mode_darkest;
        color: $dark_mode_light;
      }

    }

    .dark-mode-darkest-negative-background {
      background: $dark-negative-color;

      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .input-wrapper > label,
      .mat-select-value,
      .mat-select-arrow {
        background: $dark-negative-color;
        color: $dark_mode_light;
      }
    }

    .dark-mode-darkest-neutral-background {
      background: $dark-neutral-color;

      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .input-wrapper > label,
      .mat-select-value,
      .mat-select-arrow {
        background: $dark-neutral-color;
        color: $dark_mode_light;
      }
    }

    .dark-mode-darkest-positive-background {
      background: $dark-positive-color;

      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .input-wrapper > label,
      .mat-select-value,
      .mat-select-arrow {
        background: $dark-positive-color;
        color: $dark_mode_light;
      }
    }

    .dark-mode-dark-faded-background {
      background: rgba($dark_mode_dark, 0.7) !important;
    }

    .dark-mode-light-border {
      border-color: $dark_mode_light !important;
    }

    .dark-mode-dark-border {
      border-color: $dark_mode_dark !important;
    }

    .dark-mode-transparent-background {
      background: transparent !important;
    }

    .dark-mode-dark-icon {
      color: $dark-negative-color;
    }

    .dark-mode-light-qrcode-border > canvas {
      border: 10px solid $dark_mode_light !important;
    }

    .dark-mode-hidden {
       display: none;
       visibility: hidden;
    }

    .dark-mode-visible {
      display: inline-block;
      visibility: visible;
    }

  }

  /* LAYOUT */
  .flex-container {
    display: flex;

    &.with-wrap {
      flex-wrap: wrap;
    }

    &.spaced {
      margin: 0 -10px;

      > * {
        flex-grow: 1;
        margin: 10px;
      }
    }
  }

      table {
              border:0;

    &.styled {
      border-spacing: 0;

    > tr {
      &:nth-child(odd) {
        background: rgb(0 0 0 / 5%);
      }

      > td,
      > th {
        padding: 10px;

        &.unpadded {
          padding: 0;
        }
      }

      &.head-row {
        background: rgb(0 0 0 / 10%);
      }
    }

    .inactive {
      opacity: 0.6;
    }
  }
  }

  /* BUTTONS */
  .button-row {
    margin-bottom: 1em;

    &:last-child{
      margin-bottom:0;
    }

    button {
      width: 100%;
    }
  }

  button {
    &.styled {
      @include primary-font;

      padding: 0.9em 2em;
      min-height: 3em;
      background: $disabled-background;
      color: $disabled-foreground;
      outline: 0;
      border: 0;
      box-sizing: border-box;
      text-transform: uppercase;
      border-radius: 1.5em;
      border-style: solid;
      border-width: 2px;
      border-color: transparent;
      cursor: pointer;
      font-size: 1em;
      line-height: 1em;
      text-align: center;
      font-weight: normal;

      &.tight-rounding{
        border-radius: 5px;
      }

      &.not-rounded{
        border-radius: 0;
      }

      &.round {
        padding: 0;
        line-height: 0em;
        min-height: 0;
        height: 1.5em;
        width: 1.5em;
        text-align: center;
        font-size:2em;

        &.small{
          padding: 0;
          font-size:1em;
        }

        &.large{
          padding: 0;
          font-size:2.5em;
        }
      }

      &:active,
      &:focus {
        outline: 0;
      }

      &.small {
        font-size: 0.8em;
        border-radius: 1.5em;
        padding: 0.5em 1em;
      }

      &.large {
        font-size: 1.2em;
      }

      &.positive {
        background: $positive-color;
        color: $button-light-text-color;
      }

       &.light-positive {
        background: $light-positive-color;
        color: $button-dark-text-color;
      }

      &.neutral {
        background: $neutral-color;
        color: $button-light-text-color;
      }

      &.light-neutral {
        background: $light-neutral-color;
        color: $button-dark-text-color;
      }

      &.negative {
        background: $negative-color;
        color: $button-light-text-color;
      }

      &.light-negative {
        background: $light-negative-color;
        color: $button-dark-text-color;
      }

      &.outline {
        background: transparent;

        &.positive {
          border-color: $positive-color;
          color: $positive-color;
        }

        &.light-positive {
          border-color: $light-positive-color;
          color: $light-positive-color;
        }

        &.neutral {
          border-color: $neutral-color;
          color: $neutral-color;
        }

        &.light-neutral {
          border-color: $light-neutral-color;
          color: $light-neutral-color;
        }

        &.negative {
          border-color: $negative-color;
          color: $negative-color;
        }

        &.light-negative {
          border-color: $light-negative-color;
          color: $light-negative-color;
        }

        &:disabled,
        &.disabled {
          background: transparent;
          color: $disabled-background;
          border-color: $disabled-background;
        }
      }

      &:disabled,
      &.disabled {
        cursor: default;
        background: $disabled-background;
        color: $disabled-foreground;
      }
    }
  }

  /* ICONS */
  .icon {
    font-size: 1.2em;
    color: $base-color-5;
     width: 1em;

    &.upside-down{
      transform:rotate(180deg);
    }

    &.small {
      font-size: 0.8125em;
    }

    &.large {
      font-size: 1.5em;
    }

    &.extra-large {
      font-size: 2.5em;
      height: 1em;
    }

    &.positive {
      color: $positive-color;
    }

    &.light-positive {
      color: $light-positive-color;
    }

    &.neutral {
      color: $neutral-color;
    }

    &.light-neutral {
      color: $light-neutral-color;
    }

    &.negative {
      color: $negative-color;
    }

    &.light-negative {
      color: $light-negative-color;
    }
  }

  /* SECTIONS */
  .section-container {
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
    color: $base-color-1;
    background: $base-color-4;

          &.no-padding {
    padding: 0;
  }

    &.transparent-background{
   background:transparent;
    }

    hr {
      margin: 20px 0;
      border: 1px solid $base-color-3;
      width: 100%;

      /* Emil: satte important för at tvinga in en tunnare linje, lyfta ut HR så den blir tunn överallt? */

      /*
      border-color: rgba(0, 0, 0, 0.12);
      margin: 6px ;
      border-width: 1px 0 0;
      */
    }
  }

  .section{
        padding: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;
    border-radius: 5px;
    max-width: $section-max-width;
    text-align: left;
    color: $base-color-3;
    width: 100%;
    font-weight: 400;



    &.torn{
    border-radius: 0;
    box-shadow: none;
    margin-top: 30px;
    margin-bottom: 30px;
    filter: drop-shadow(0 0 2px rgb(50 50 0 / 30%)) drop-shadow(1px 2px 0 rgb(50 50 0 / 20%));

    &::before, &::after {
        content: "";
        background: white;
        height: 20px;
        position: absolute;
        left: 0;
        right: 0;
        clip-path: polygon(0% 0%, 5% 55%, 12% 21%, 17% 72%, 21% 9%, 28% 87%, 29% 40%, 34% 59%, 39% 13%, 47% 86%, 52% 12%, 54% 46%, 58% 56%, 62% 40%, 68% 71%, 72% 40%, 79% 18%, 84% 72%, 92% 23%, 97% 63%, 100% 0%);
        clip-path: polygon(0% 0%, 5% 55%, 12% 21%, 17% 72%, 21% 9%, 28% 87%, 29% 40%, 34% 59%, 39% 13%, 47% 86%, 52% 12%, 54% 46%, 58% 56%, 62% 40%, 68% 71%, 72% 40%, 79% 18%, 84% 72%, 92% 23%, 97% 63%, 100% 0%);
      }

      &::before {
        top: -20px;
        transform: rotate(180deg);
      }

      &::after {
        bottom: -19px;
      }
}


    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%),
      0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);

    &:not(.reset-background){
          background: white;

         &.transparent{
      background:transparent;
      box-shadow:none;
    }
    }

      &.no-padding {
    padding: 0;
  }

    &.fullwidth{
      max-width: 100%;
      width:100%;
    }

    &.selected{
      box-shadow: 0 4px 4px 0 rgb(0 0 0 / 34%), 0 5px 2px -4px rgb(0 0 0 / 42%), 0 3px 11px 0 rgb(0 0 0 / 50%);
    }
  }

  .section,
  .no-section {
    table {
      width: 100%;
      border: 0;

      tr {
        td,
        th {
          border: 0;

          &.indented {
            padding-left: 10px;
          }
        }
      }
    }

    hr {
      border-color: rgb(0 0 0 / 12%);
      margin: 8px 16px;
      width: auto;
      border-width: 1px 0 0;
    }

    /* INPUTS */
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"],
        input[type="number"],
    textarea {
      @include primary-font;

      appearance: none;
      appearance: none;
      appearance: none;
      padding: 0.6em;
      border: 1px solid $base-color-2;
      box-sizing: border-box;
      outline: 0;
      width: 100%;
      background: white;
      margin: 2px 0;
      border-radius: 0.3em;
      line-height: 0.9em;
      color: $base-color-3;

      &::placeholder {
        color: $base-color-2;
        opacity: 1;
      }

      &:input-placeholder {
        color: $base-color-2;
      }

      &::input-placeholder {
        color: $base-color-2;
      }

      &:focus {
      //  border-width: 2px;
    //    margin: 4px 0px;
        border-color: $neutral-color;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: "color 9999s ease-out, background-color 9999s ease-out";
        transition-delay: 9999s;
      }
    }

    textarea{
      min-height: 50px;
    }

    .input-container {
      .input-wrapper {
        &:first-child,
        &.no-top-margin {
          margin-top: 0;

          label {
         //   top: -0.7em;
          }

          .mat-mdc-select {
          margin:0;
          }

          input[type="text"],
          input[type="email"],
          input[type="tel"],
          input[type="password"],
          input[type="number"],
                input[type="number"],
          textarea {
            margin-top: 1px;

            &:focus {
            //  margin-top: 0px;
            }
          }
        }

        &:last-child,
        &.no-bottom-margin {
          margin-bottom: 0;

          input[type="text"],
          input[type="email"],
          input[type="tel"],
          input[type="password"],
                input[type="number"],
          textarea {
            margin-bottom: 1px;

            &:focus {
           //   margin-bottom: 0px;
            }
          }
        }
      }
    }

    .input-wrapper {
      position: relative;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      line-height: 1em;

      > label {
        position: absolute;
        font-weight: normal;
        color: $base-color-2;
        font-size: 1em;
        top: -0.3em;
        left: 0.8em;
        padding: 0 3px;
        background: white;
        border-radius: 100%;
      }

      .validation-message{
        margin-left:5px;
      }

      &.required {
        label {
          &::after {
            content: "*";
            color: $negative-color;
          }
        }

      }

      .mat-mdc-select {
        padding: 0.6em;
        border: 1px solid $base-color-2;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        margin: 5px 0;
        border-radius: 0.3em;
        text-align: left;
      }

      i {
        position: absolute;
        top: 50%;
        right: 0.2em;
        font-size: 1.9em;
        transform: translateY(-50%);
        background: white;


        &.clickable {
          opacity: 0.8;
          transition: 0.3s ease;

          &:hover {
            opacity: 1;
          }
        }

        @keyframes spin {
          from {
            transform: translateY(-50%) rotate(0deg);
          }

          to {
            transform: translateY(-50%) rotate(-360deg);
          }
        }

        &.spinning {
          border-radius: 100%;
          transform-origin: center;
          animation: spin 2s linear infinite;
        }
      }

      &.invalid {
        label,
        .validation-message{
          color: $negative-color;
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="password"],
            input[type="number"],
        textarea {
          border-color: $negative-color;
          display: inline-block;
          vertical-align: text-bottom;

          &:focus {
            + label {
              color: $negative-color;
            }
          }
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="password"],
          input[type="number"],
      textarea {
        font-size: 1em;
        height: auto;

        &:focus {
          + label {
            font-weight: normal;
            color: $neutral-color;
          }
        }
      }

      textarea {
        padding-top: 1em;
        line-height: 1em;
      }
    }

    .text-icon {
      .icon {
        vertical-align: middle;
        margin-right: 0.2em;

        &.fa,
        &.fas{
          margin-right:0.4em;
        }
      }

      .text {
        vertical-align: middle;
        display: inline-block;
      }
    }

    .text-icons {
      padding: 0;
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;


      &:not(.no-wrap){
        .text-icon{
              min-width: 33%;

          // min-width:0px;
          .text{
                    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
          }
        }

      }

      &.no-wrap {
        display: flex;
            flex-wrap: nowrap;

        .text-icon {
          flex-grow: 1;
          flex-basis: 0;
        }

        &.compact{
         .text-icon {
          flex: 0 1 0;
        }
        }

        &.divided{
          .text-icon:not(:first-child) {
          position:relative;

            &::before{
              content:'';
              display:block;
              position:absolute;
              border-left: 1px solid $base-color-2;
              width:1px;
              left: -0.5em;
               height: 100%;
              opacity: .4;
            }
          }
        }
      }

      @media screen and (max-width: #{$breakpoint-s}px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .text-icon {
        white-space: nowrap;
        line-height: 1.5em;

        &:not(:last-child) {
          padding-right: 1em;
        }
      }
    }
  }

  /* Google Material rules */

  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  .mat-chip-list-wrapper {
    margin: 0;
  }

  .mat-mdc-checkbox,
  .mat-mdc-radio-button {
    @include primary-font;

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mdc-label{
        white-space: pre-wrap;
        line-height: 1em;
    }

    &.positive {
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
      &.mat-mdc-checkbox-checked .mdc-checkbox__background,
      .mat-radio-inner-circle,
      .mat-mdc-checkbox-ripple  {
        background-color: $positive-color !important;
        border-color: $positive-color !important;
      }

      .mdc-checkbox__background {
        border-color: $positive-color !important;
      }

      .mdc-checkbox__checkmark {
        color: $button-light-text-color !important;
      }

      .mdc-label {
        color: $positive-color !important;
      }

    }

    &.neutral {
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
      &.mat-checkbox-checked .mat-checkbox-background,
      .mat-radio-inner-circle,
      .mat-ripple-element {
        background-color: $neutral-color;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
      .mat-checkbox-frame,
      .mat-radio-outer-circle{
        border-color: $neutral-color;
      }
    }

    &.negative {
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
      &.mat-checkbox-checked .mat-checkbox-background,
      .mat-radio-inner-circle,
      .mat-ripple-element  {
        background-color: $negative-color;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
      .mat-checkbox-frame,
      .mat-radio-outer-circle {
        border-color: $negative-color;
      }
    }
  }

  .mat-mdc-form-field{
    &.mat-focused{
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label{
        color: $neutral-color;
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-form-field-outline{
    color:$base-color-2;

  }

  /* Bootstrap rules */

  .row{
    .no-left-padding{
      padding-left:0;
    }

    .no-right-padding{
      padding-right:0;
    }
  }
}


