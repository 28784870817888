/* You can add global styles to this file, and also import other style files */
@import 'node_modules/bootstrap/scss/bootstrap-reboot';
@import 'node_modules/bootstrap/scss/bootstrap-grid';

//@import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import 'scss/indigo-pink';

//@import url("https://fonts.googleapis.com/css?family=Reem+Kufi");
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700';

//@import "node_modules/@fullcalendar/core/main.css";
//@import "node_modules/@fullcalendar/timeline/main.css";
//@import "node_modules/@fullcalendar/resource-timeline/main.css";
@import 'scss/colors';
@import 'scss/loader-icon';
@import 'scss/check-in-calendar';
@import '../../../src/scss/styleguide.scss';
@import "./scss/custom-icons.scss";
//var(--fa-font-path): "node_modules/font-awesome/fonts";
@import 'node_modules/font-awesome/scss/font-awesome.scss';


/* Horizontal scrollbar */
::-webkit-scrollbar {
    background-color:transparent;
     width: 12px;
     height: 12px;
     transition: all 0.3s ease-in-out;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: rgb(255 255 255 / 6%);
    margin: 5px;
    border-radius: 30px;
    border: 4px solid var(--background-color);
}

::-webkit-scrollbar-thumb:hover {
    border:4px solid var(--primary-color);
}



/* Thumb */
::-webkit-scrollbar-thumb {
    background-color:var(--primary-color);
    border:4px solid var(--background-color);
    border-radius: 30px;
    box-shadow: inset 0 0 2px rgb(0 0 0 / 30%);
}

/* Hide scrollbar buttons */
::-webkit-scrollbar-button {
    display: none;
}

body,
.mat-mdc-table,
.mat-mdc-form-field,
.mat-mdc-button,
.mat-mdc-fab,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
    font-family: 'Open Sans', sans-serif;
}

html{
    background-color: var(--background-color);
}

:root {
    //--background-color:#17181d;
    --background-color: #e0e0e0;
    --dark-background-color: #1e1f24;
    --wrapper-background-color: #fcfbfb;
    --text-base: #000;
    --text-opposite: #fff;
    --scrollbar-color: #fff;
    --bubble-color: #333;
    --bubble-text: #fff;
    --card-bg:rgb(0 0 0 / 5%) ;
    --table-hover: #00000006;


    --base-color-1: #343d46;
    --base-color-2: #a7adba;
    --base-color-3: #4f5b66;
    --base-color-4: #fcfcfc;
    --base-color-5: #d9c0d9;

    //--wrapper-background-color:#1e1f24;
    --header-background: #040023;
    --header-color: var(--wrapper-background-color);
    --mat-tab-header-pagination-icon-color: var(--neutral);

    //--primary-color:#c1ff00;
    --primary-color: #333;
    --button-background: var(--primary-color);
    --button-color: var(--background-color);
    --calendar-background: #efefef;
    --calendar-color: #333;
    --calendar-selected-color: #46a8cb;
    --calendar-event-background-color: #f0f0f0;
    --calendar-resource-booking-event-background-color: #fdf2dc;
    --calendar-blocked-resource-booking-event-background-color: #ffdfdf;
    --calendar-resource-available-color: #93c37a;
    --calendar-resource-has-bookings-color: #cde4af;
    --calendar-resource-almost-full-color: #fffbb4;
    --calendar-resource-full-color: #f0b3cd;
    --calendar-now-indicator: #ff3087;
    --green: #66af48;
    --daily-notes-bg:#fff;
    --box-shadows:0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    --daily-notes-text: #000;
    --mdc-checkbox-selected-icon-color: var(--neutral);
    --mat-tab-header-inactive-hover-label-text-color: var(--neutral);
    --wrapper-box-shadow: 0 2px 2px #00000024,0 3px 1px -2px #0000001f,0 1px 5px #0003;
    --mat-tab-header-inactive-focus-label-text-color: rgb(0 0 0 / 60%);
    --mat-tab-header-inactive-hover-label-text-color: rgb(0 0 0 / 60%);
    //--yellow:#e4ff00;
    --yellow: #fef058;
    --red: #d33372;
    --negative: #d33372;
    --purple: #ff6bd3;
    --positive: #66af48;
    --neutral: #46a8cb;
    --neutral-inactive: #7dd4f1c9;
    --grey: #333;
    --light-grey: #a2a2a2;
    --dark-red: #d20b0b;
    --dark-green: #239e04;
    --procommerca-red: #d33372;
    --nav-icons: #0f0f0f;
    --disabled: var(--light-grey);


    --calendar-hover: #00000010;


    .mat-accent {
        --mat-option-selected-state-label-text-color: var(--positive);
    }

    .NgxEditor__Wrapper{


        .NgxEditor__MenuBar.NgxEditor--Disabled:not(.owo){
            opacity: 1 !important;
            .NgxEditor__MenuItem:not(.skip) {
                opacity: 0.25 !important;
            }
            .NgxEditor__Dropdown:not(.skip) {
                opacity: 0.25 !important;
            }
            .NgxEditor__MenuItem.skip {
                pointer-events: all;
            }
        }}
    
}

[data-theme='dark'] {
    --background-color: #101204;
    --background-color-hover: #15153d;
    --scrollbar-color: #101204;
    --header-background:#000;
    --card-bg:#22272B ;
    --header-color: #000;
    --nav-icons: #fff;
    --text-base: #fff;
    --text-opposite: #030303;
    --mat-icon-color: #fff;
    --primary-color: #fff;
    --box-shadows:0 2px 2px 0 rgb(0 0 0 / 100%), 0 3px 1px -2px rgb(0 0 0 / 100%), 0 1px 5px 0 rgb(0 0 0 / 100%);
    --bubble-color: #15153d;
    --bubble-text: #fff;
    --calendar-background: #101204;
    --calendar-event-background-color: #22272B;
    --calendar-resource-booking-event-background-color:#43361c ;
    --mat-tab-header-pagination-icon-color: var(--neutral);
    --mat-tab-header-inactive-focus-label-text-color: rgb(255 255 255 / 60%);
    --mat-tab-header-inactive-hover-label-text-color: rgb(255 255 255 / 60%);
    --calendar-color: #dfdbdb;
    --calendar-now-indicator: #ff3087;
    --mat-datepicker-calendar-container-background-color: #101204;
    --mat-datepicker-calendar-container-text-color:#fff;
    --mat-sidenav-content-background-color: #020204 !important;
    --mat-sidenav-content-text-color: #fff !important;
    --mdc-filled-text-field-container-color: #22272B;
    --mdc-filled-text-field-input-text-color: #fff !important;
    --mdc-filled-text-field-label-text-color: #c4c4c5;
    --mat-tab-header-inactive-label-text-color:#ffffffc9 !important;
    --mdc-dialog-subhead-color: #ffffffd5 !important;
    --mdc-dialog-supporting-text-color: #ffffffe7 !important;
    --mat-select-enabled-trigger-text-color: #fff !important;
    --mdc-checkbox-unselected-icon-color: #fff;
    --fc-border-color: #39396479;
    --daily-notes-bg:var(--header-background);
    --daily-notes-text: #fff;
    --mat-datepicker-calendar-date-text-color: #fff;
    --mdc-theme-text-primary-on-background: #fff;
    --wrapper-background-color: #020206;
    --wrapper-box-shadow:2px 2px 10px rgb(68 68 68 / 20%) ;
    --mdc-filled-text-field-disabled-container-color:#22272bc0;
    --mdc-filled-text-field-disabled-label-text-color: rgb(var(--neutral) / 38%);
    --mdc-filled-text-field-disabled-input-text-color:rgb(var(--neutral) / 38%);
    --mat-datepicker-calendar-header-text-color:#fff ;
    --mat-datepicker-calendar-body-label-text-color:#fff ;
    --mat-datepicker-calendar-period-button-icon-color:#fff ;
    --mat-select-panel-background-color:#22272B;
    --mat-option-label-text-color: #fff;
    --mat-select-enabled-arrow-color: #fff;
    --table-color: #22272B;
    --table-hover: #ffffff08;

    --base-color-1: #b7c1ca;
    --base-color-2: #bcc5da;
    --base-color-3: #4f5b66;
    --base-color-4: #000000;
    --base-color-5: #adadad;

    //Ngx-editor styles
    .NgxEditor__Wrapper{
        --ngx-editor-border-radius: 4px;
        --ngx-editor-background-color: #22272B;
        --ngx-editor-text-color: #fff;
        --ngx-editor-placeholder-color: #6c757d;
        --ngx-editor-border-color: rgb(0 0 0 / 20%);
        --ngx-editor-wrapper-border-color: rgb(121 121 121 / 20%);
        --ngx-editor-menubar-bg-color: #22272B;
        --ngx-editor-menubar-padding: 3px;
        --ngx-editor-menu-item-hover-bg-color: #4f4f4fb0;
        --ngx-editor-menubar-height: 30px;
        --ngx-editor-blockquote-color: #ddd;
        --ngx-editor-blockquote-border-width: 3px;
        --ngx-editor-icon-size: 30px;
        --ngx-editor-popup-bg-color: #22272B;
        --ngx-editor-popup-border-radius: 4px;
        --ngx-editor-popup-shadow: rgb(60 64 67 / 15%) 0px 2px 6px 2px;
        --ngx-editor-menu-item-border-radius: 2px;
        --ngx-editor-menu-item-active-color: var(--neutral);
        --ngx-editor-menu-item-active-bg-color: #00000026;
        --ngx-editor-seperator-color: #656565;
        --ngx-editor-bubble-bg-color: #000;
        --ngx-editor-bubble-text-color: #fff;
        --ngx-editor-bubble-item-hover-color: #00000026;
        --ngx-editor-bubble-seperator-color: #fff;
        --ngx-editor-focus-ring-color: #5e9ed6;
        --ngx-editor-error-color: red;
        --ngx-editor-click-pointer: default;

        .NgxEditor__MenuBar.NgxEditor--Disabled:not(.owo){
            opacity: 1 !important;
            .NgxEditor__MenuItem:not(.skip) {
                opacity: 0.25 !important;
            }
            .NgxEditor__Dropdown:not(.skip) {
                opacity: 0.25 !important;
            }
            .NgxEditor__MenuItem.skip {
                pointer-events: all;
            }
        }
    
    }

    .mat-mdc-button.mat-unthemed {
        --mdc-text-button-label-text-color: #fff;
    }

    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        --mdc-checkbox-unselected-hover-icon-color : var(--neutral-inactive);
    }

    .mat-mdc-tab:hover .mdc-tab__text-label {
        --mat-tab-header-inactive-hover-label-text-color : var(--neutral-inactive);
    }


    .mat-mdc-tab-group{
        --mat-tab-header-inactive-label-text-color: #ffffffc9 !important;
        --mat-tab-header-pagination-icon-color: #ffffffc9 !important;
        --mat-tab-header-inactive-hover-text-color: rgb(255 255 255 / 60%);
    }

    .mat-mdc-dialog-container {
        --mdc-dialog-container-color: var(--background-color-hover);
        --mdc-dialog-subhead-color: #ffffffd5;
        --mdc-dialog-supporting-text-color: #ffffffe7 ;
    }

    body header {
        border-bottom: none;
    }

    input.fullheight.align-center.dark-mode-light-color.ng-pristine.ng-valid.ng-touched {
        color: #fff;
    }

    .fc-theme-standard .fc-scrollgrid {
        border: none;
    }

    mat-sidenav-container mat-sidenav.mat-drawer{
        background: linear-gradient(to right, #101204, #101204a4);
    }

    .mat-drawer{
        color: var(--text-base)
    }

    mat-icon.mat-icon.notranslate.dialog-close-icon.clickable.material-icons.mat-ligature-font.mat-icon-no-color{
        color: #fff;
        background-color: transparent;

        &:hover{
            background-color: var(--background-color-hover);
        }
    }

    svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
        color: #fff;
    }

    div.fc-resourceTimeline-view.fc-view.fc-resource-timeline.fc-resource-timeline-flat.fc-timeline.fc-timeline-overlap-enabled {
        background-color: var(--background-color);
    }

    mat-dialog-content {
        background-color: var(--background-color);
        color: var(--text-base) !important;
    }

    body table.mat-mdc-table {
        background: var(--table-color) !important;
    }

    mat-dialog-actions.mat-mdc-dialog-actions.mdc-dialog__actions {
        background-color: var(--background-color);
    }
}

.booking-search-dialog-event-booking, .booking-search-dialog-booking, .booking-search-dialog-resource-booking{
    box-shadow: var(--box-shadows);
    border-radius: 5px;
    height: 100% !important;
    width: 100% !important;
}

.booking-search-dialog-event-booking.canceled, .booking-search-dialog-booking.canceled, .booking-search-dialog-resource-booking.canceled{
    background: rgb(255 48 135 / 6%) !important;
    opacity: 0.7 !important;
}


.booking-search-dialog-event-booking, .booking-search-dialog-booking{
    background-color: var(--card-bg) !important;
}

.booking-search-dialog-resource-booking{
    background-color: #d8a1343b !important;
    background-size: 56.57px 56.57px;
    background-image: linear-gradient(45deg, rgb(0 0 0 / 2%) 25%, rgb(255 255 255 / 0%) 25%, rgb(255 255 255 / 0%) 50%, rgb(0 0 0 / 2%) 50%, rgb(0 0 0 / 2%) 75%, rgb(255 255 255 / 0%) 75%, rgb(255 255 255 / 0%) 100%);;
}


.mat-mdc-option {
    .mdc-list-item__primary-text {
        white-space: nowrap !important;
    }
}

.mat-mdc-form-field {
    margin-top: 10px;

    &.time-input {
        width: 7em;
    }
}

.friendly-code-title {
    display: inline-block;
    padding-right: 10px;
    padding-left: 15px;
}

.friendly-code {
    display: inline-block;
}

.underline {
    text-decoration: underline;
}

/*
input[type="number"][matInput] {
  outline: 0px;
  border: 0px;
  border-bottom: 1px solid black;
  background: whitesmoke;
  //padding: 8px;
}
*/

.mat-mdc-dialog-surface {
    overflow-x: hidden;
}

mat-form-field.mat-form-field-disabled label {
    opacity: 0.6;
}

.mat-mdc-select-panel {
    min-width: fit-content !important;
}

body {
    background: var(--background-color);
    color: var(--primary-color);
    overflow-x: hidden;

    input[type='number'][matInput]:not(.mat-mdc-form-field input[type='number'][matInput]) {
        outline: 0;
        border: 0;
        border-bottom: 1px solid black;
        background: whitesmoke;
        padding: 8px;
    }

    label {
        margin-bottom: initial;
    }

    .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;

        &.break {
            line-break: anywhere;
        }
    }

    .no-border {
        border-width: 0 !important;
    }

    button:focus {
        outline: initial;
    }

    .print-show {
        visibility: collapse !important;
        height: 0;
    }

    table.inverted {
        tr {
            th {
                font-weight: normal;
            }

            td {
                font-weight: bold;
            }
        }
    }

    .mat-mdc-dialog-actions {
        box-sizing: border-box;
    }

    .mat-mdc-row {
        height: 1.5em;
    }

    @media print {
        .print-hide {
            visibility: collapse !important;
            display: none !important;
        }

        .print-show {
            visibility: visible !important;
            height: auto;
        }
    }

    .mat-mdc-tab-body-content {
        overflow: hidden;
    }

    header {
        background: var(--header-background);
        border-bottom: 2px solid var(--primary-color);
        height: 50px;
        padding: 5px;

        .logo {
            max-height: 40px;

            //margin-top: 6px;
            margin-left: 5px;
        }

        .client-name-box,
        .version-box {
            background: var(--neutral);
            color: var(--header-background);
            padding: 8px;
            font-size: 14px;
            position: fixed;
            display: inline-block;
            z-index: 9;

            a {
                color: var(--header-background);
            }
        }

        .client-name-box {
            left: 50vw;
            transform: translateX(-50%);
            top: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .version-box {
            left: 0;
            bottom: 0;
            border-top-right-radius: 10px;
            z-index: 9999;
            font-weight: bold;
            background: var(--purple);
        }

        .pinned-notification {
            color: var(--header-background);
            font-size: 14px;
            padding-right: 20px;
            display: inline-block;
            max-width: 50%;
            white-space: nowrap;
            vertical-align: top;
            transition: 0.2s linear;
            margin-top: 3px;

            &:hover {
                transform: scale(1.05);

                .message span {
                    text-decoration: underline;
                }
            }

            .icon-circle {
                border-radius: 100%;
                background: var(--neutral);
                width: 40px;
                height: 40px;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                transform: translateY(1px);

                .mat-icon {
                    vertical-align: middle;
                    font-size: 30px;
                    transform: translateX(-3px) translateY(5px);
                }
            }

            .message {
                background: transparent;
                color: white;
                padding: 5px;
                vertical-align: middle;

                //margin-left: -20px;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 20px;
                max-width: 100%;
                display: inline-block;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;

                img {
                    height: 24px;
                    margin-left: -5px;
                    margin-right: 2px;
                }

                .mat-icon {
                    margin-right: 2px;
                    margin-left: -5px;
                    vertical-align: middle;
                }

                span {
                    vertical-align: middle;
                }
            }
        }

        .user-info {
            color: white;
            display: inline-block;
            font-size: 12px;
            vertical-align: top;
            margin-right: 10px;

            a {
                color: white;
            }
        }

        .menu-button {
            color: white;
            font-size: 46px;
            margin-right: 20px;
            margin-top: -4px;
            height: 46px;
            width: 46px;
        }

        ul.top-menu {
            list-style-type: none;
            margin: 0;

            li {
                float: left;
                padding: 10px;

                a {
                    color: var(--primary-color);
                    margin-right: 10px;

                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }

    .hidden {
        display: none;
    }

    table.styled {
        > tr {
            &:nth-child(odd) {
                background: rgb(0 0 0 / 5%);
            }

            > td,
            > th {
                padding: 10px;

                &.unpadded {
                    padding: 0;
                }

                &.indented {
                    padding-left: 20px;
                }
            }

            &.head-row {
                background: rgb(0 0 0 / 10%);
            }
        }

        .inactive {
            opacity: 0.6;
        }
    }

    .capitalize {
        text-transform: capitalize;
    }

    button.styled,
    button.styled.mdc-button {
        padding: 10px;
        background: var(--neutral);
        color: white;
        outline: 0;
        border: 0;
        box-sizing: border-box;
        height: auto;
        line-height: 2.5em;

        &.mat-mdc-button.mat-mdc-button-base,
        &.mat-mdc-raised-button.mat-mdc-button-base,
        &.mat-mdc-unelevated-button.mat-mdc-button-base,
        &.mat-mdc-outlined-button.mat-mdc-button-base &:active {
            height: auto;
        }

        &:focus {
            outline: 0;
        }

        &:disabled {
            opacity: 0.6;
            color: white;
        }

        &.neutral {
            background: var(--neutral);
            border-color: var(--neutral);

            &.outline {
 opacity: 0.6;
            color: white;
                color: var(--neutral);
            }
        }

        &.green {
            background: var(--green);
            border-color: var(--green);

            &.outline {
                color: var(--green);
            }
        }

        &.red {
            background: var(--red);
            border-color: var(--red);

            &.outline {
                color: var(--red);
            }
        }

        &.outline {
            background: transparent;
            border-style: solid;
            border-width: 2px;
        }

        &.big {
            font-size: 18px;
        }
    }

    .green-text {
        color: var(--green);
    }

    .red-text {
        color: var(--red);
    }

    .yellow-text {
        color: var(--yellow);
    }

    .line-through {
        text-decoration: line-through;
    }

    table.mat-mdc-table {
        background: rgb(255 255 255 / 2%);

        &.fixed {
            table-layout: fixed;
        }

        tr {
            &.disabled {
                opacity: 0.2;
            }

            &.selectable:hover {
                background-color: var(--table-hover);
            }

            td,
            th {
                color: var(--primary-color);
            }

            td {
                //vertical-align: top;
            }
        }
    }
}


.foresight-select,
.payment-required-select,
.grace-period-select {
  .inner {
   //font-size: 20px;
    .mat-mdc-select {
      width: auto;
      padding: 2px;
      padding-left: 5px;
      padding-right: 5px;
      font-weight: bold;
      margin-right: 5px;
      border: 1px solid rgb(0 0 0 / 20%);
      border-radius: 5px;

      ::ng-deep {
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
        .mat-select-value {
          display: inline;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
        .mat-select-arrow-wrapper {
          display: none;
        }
      }
    }
  }
}

.fullwidth {
    width: 100%;
}

.fullheight {
    height: 100%;
}

.align-right {
    text-align: right !important;
}

.align-left {
    text-align: left !important;
}

.align-center {
    text-align: center !important;
}

.valign-middle {
    vertical-align: middle;
}

.valign-top {
    vertical-align: top;
}

.valign-bottom {
    vertical-align: bottom;
}

.valign-sub {
    vertical-align: sub;
}

.inline-block {
    display: inline-block;
}

.flex-end{
    justify-content: flex-end !important;
    padding: 2px;
}

.padded {
    padding: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-list .mat-tab-label {
    min-width: 50px;
    padding: 0 10px;
}

.color-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin: 5px;
    margin-bottom: 25px;
    font-size: 40px;
    transform: translateY(8px);
}

.mat-icon {
    &.big {
        height: 40px;
        width: 40px;
        font-size: 40px;
    }
}

.mat-mdc-card {
    &.primary-background {
        background: var(--background-color) !important;
    }
}

.primary-background {
    background: var(--background-color);
}

hr {
    border-color: var(--primary-color);

    &.light {
        opacity: 0.1;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-body .mat-tab-body-content {
    overflow-x: hidden;
}

.main {
    margin-top: 20px;
    background: var(--wrapper-background-color);
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: var(--wrapper-box-shadow);

    &.main {
        width: 90vw;
    }
}

.clickable {
    cursor: pointer;
}

input {
    color: var(--primary-color);

    &::placeholder,
    &::input-placeholder {
        color: var(--primary-color);
    }
}

table.log {
    tr.info {
        th,
        td {
            color: var(--green);
        }
    }

    tr.warning {
        th,
        td {
            color: var(--yellow);
        }
    }

    tr.error {
        th,
        td {
            color: var(--red);
        }
    }
}

dl-date-time-picker {
    .dl-abdtp-date-button[_ngcontent-c6],
    .dl-abdtp-left-button[_ngcontent-c6],
    .dl-abdtp-right-button[_ngcontent-c6],
    .dl-abdtp-view-label[_ngcontent-c6] {
        color: var(--primary-color);
        text-align: center;
    }

    .dl-abdtp-date-button,
    .dl-abdtp-up-button {
        color: var(--primary-color) !important;
    }

    .dl-abdtp-selected {
        background: var(--primary-color) !important;
        color: var(--background-color) !important;
    }

    button {
        background-color: transparent;
    }

    .dl-abdtp-selected[_ngcontent-c6] {
        background-color: var(--primary-color);
        color: black;
    }
}

.selected-date {
    background: var(--background-color);
    color: white;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    display: inline-block;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-content {
    overflow-x: hidden;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-group .mat-tab-label-active {
    opacity: 1;
}

.tooltip-icon {
    color: var(--primary-color);
    margin: 5px;
    vertical-align: super;
    font-size: 25px;
    opacity: 0.3;
    transition: 0.3s ease;

    &:hover {
        opacity: 1;
    }
}

th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
    //border-bottom-width: 0px;
}

.mat-mdc-tooltip.tooltip-content {
    color: var(--primary-color);
    font-size: 1.1em;
}

.mat-tooltip-linebreak {
    white-space: pre-wrap;
}

.mat-mdc-form-field.slim {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
        padding-top: 0;
        border-top: 0;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-underline,
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--primary-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-select-value,
.mat-select-arrow,
.mat-tab-label,
.mat-tab-link {
    color: var(--primary-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-frame {
    border-color: var(--primary-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--button-background);
}

.mat-mdc-fab.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-mini-fab.mat-primary,
.mat-mdc-raised-button.mat-primary {
    background-color: var(--button-background);
    color: var(--button-color);
}

.bottom-margin-button,
.mat-mdc-raised-button.bottom-margin-button {
    vertical-align: bottom;
    margin-bottom: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-checkbox {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-checkmark-path {
        stroke: var(--button-color) !important;
    }

    &.large {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-inner-container {
            height: 25px;
            width: 25px;
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.transparent {
    background: transparent;
    color: var(--primary-color);
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.users {
    tr.manager {
        th,
        td {
            color: var(--green);
        }
    }

    tr.user {
        th,
        td {
            color: var(--yellow);
        }
    }

    tr.disabled {
        opacity: 0.2;
    }
}

img.illustration {
    max-width: 100%;
    width: 100px;

    &.small {
        width: 50px;
    }

    &.large {
        width: 200px;
    }

    &.extra-large {
        width: 400px;
    }
}

.associated-resources,
.extra-activities,
.sortaable-table {
    tbody {
        tr:first-child {
            .up-arrow {
                opacity: 0.2;
                cursor: default;
            }
        }

        tr:last-child {
            .down-arrow {
                opacity: 0.2;
                cursor: default;
            }
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-content .mat-icon {
    vertical-align: bottom;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix > label,
label.styled {
    opacity: 0.5;
}

mat-sidenav-container {
    min-height: 100vh;

    .mat-drawer-inner-container {
        -ms-overflow-style: none;

        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;

            /* Chrome */
        }
    }

    mat-sidenav-content.mat-drawer-content {
        min-height: 100vh;
        overflow-x: hidden;
    }

    mat-sidenav.mat-drawer {
        background: linear-gradient(to right, #fff, rgb(255 255 255 / 74%));

        .side-menu {
            padding: 10px;
            margin-top: 50px;

            ul {
                list-style-type: none;
                padding-left: 20px;
                margin-bottom: 15px;

                li {
                    font-size: 14px;

                    //font-weight: bold;
                    border-bottom: 2px solid #00000021;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    overflow: visible;
                    position: relative;

                    .mat-icon {
                        color: var(--primary-color);
                        margin-right: 8px;
                        margin-left: 8px;
                        transform: translateY(6px);
                    }

                    i {
                        color: var(--primary-color);
                        margin-right: 8px;
                        margin-left: 8px;

                        &.fa,
                        &.fas {
                            margin-top: 6px;
                            margin-bottom: 6px;
                            margin-right: 15px;
                        }
                    }

                    a {
                        color: var(--primary-color);

                        &:focus,
                        &:active {
                            outline: none;
                            border: none;
                        }
                    }

                    .beta-icon {
                        background: var(--neutral-color);
                        border-radius: 100%;
                        padding: 3px;
                        position: absolute;
                        top: 2px;
                        left: -5px;
                        color: white;
                        font-size: 9px;
                        font-weight: bold;
                    }

                    ul li {
                        ul.submenu {
                            margin-left: 10px;
                            margin-bottom: 0;
                            opacity: 0;
                            max-height: 0;
                            transition: 0.4s ease-in;

                            li:last-child {
                                border-bottom: 0;
                            }

                            &.expanded {
                                opacity: 1;
                                max-height: 1000px;
                            }
                        }
                    }
                }
            }

            > ul {
                padding-left: 0;

                > li {
                    border: 0;
                }
            }
        }
    }
}

input.mat-mdc-input-element {
    line-height: 1em;
}

.article-number-chooser-fix {
    margin-top: 16px;
    display: block;
}

pre.code {
    width: 100%;
    white-space: pre-wrap;
}

.campaign-label-preview {
    margin: 5px;
    padding: 5px 15px;
    display: table;
    border-radius: 5px;
}

.dialog-close-icon {
    position: absolute !important;
    right: 20px !important;
    top: 20px !important;
    background: white;
    z-index: 99999999999;
    border-radius: 5em;

    &:hover {
        cursor: pointer;
        border-radius: 1000em;
        background-color: var(--base-color-2);
    }
}

.right-head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.center-in-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: var(--neutral);
    --mat-tab-header-disabled-ripple-color: rgb(0 0 0 / 38%);
    --mat-tab-header-pagination-icon-color: #000;
    --mat-tab-header-inactive-label-text-color: rgb(0 0 0 / 60%);
    --mat-tab-header-active-label-text-color: var(--neutral);
    --mat-tab-header-active-ripple-color: var(--neutral);
    --mat-tab-header-inactive-ripple-color: var(--neutral);
    --mat-tab-header-inactive-focus-label-text-color: rgb(0 0 0 / 60%);
    --mat-tab-header-inactive-hover-label-text-color: rgb(0 0 0 / 60%);
    --mat-tab-header-active-focus-label-text-color: var(--neutral);
    --mat-tab-header-active-hover-label-text-color: var(--neutral);
    --mat-tab-header-active-focus-indicator-color: var(--neutral);
    --mat-tab-header-active-hover-indicator-color: var(--neutral);
}

.booking-search-dialog-booking{
    border-radius: 5px;
    box-shadow: var(--box-shadows);
}

booking-search-dialog {
    height: 100%;
    width: 100% !important;
    display: block;
}

.booking-search-dialog.booking {
    height: 100% !important;
    width: 100% !important;
}

.gridwrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 6px;
}

.flex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.flex-start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.topbutton{
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: transparent;
    color: var(--negative);
}

.relative{
    position: relative;
}

.nondraggable{
    height: 24px;
    resize: none !important;
}

//add-on-administration

.centered{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
}


.centercol{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px;
    background-color: rgb(0 0 0 / 6.8%);
}


.variant{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 6px;
    border-radius: 6px;
    background-color: white;
    border: 1px solid black;
}

.gap-4{
    gap:4px
}

.wrap{
    flex-wrap: wrap;
}


.heading-3{
    color: #a7adba;
    font-size: 1.33rem
}

.subhead-3{
    color: black;
    font-size: 1rem;
}

.desc-3{
    color: #a7adba;
    font-size: 0.9rem;
}

.pl-2{
    padding-left: 2px;
}

.positive-bg{
    background-color:#66af48 !important ;
    color: white;
}

.negative-bg{
    background-color:#d33372 !important ;
    color: white;
}


// .styled{
//         font-family: Roboto,sans-serif;
//         padding: .9em 2em;
//         min-height: 3em;
//         background: #a7adba;
//         color: #fcfcfc;
//         outline: 0;
//         border: 0;
//         box-sizing: border-box;
//         text-transform: uppercase;
//         border-radius: 1.5em;
//         border-style: solid;
//         border-width: 2px;
//         border-color: transparent;
//         cursor: pointer;
//         font-size: 1em;
//         line-height: 1em;
//         text-align: center;
//         font-weight: 400;
// }

.transition{
    transition: all 0.3s ease-in-out;
}

//Charity

.charity-image {
    max-width: 300px;
}


//Extenda

.notice-box {
    padding: 10px;
    border-radius: 10px;
    color: var(--base-color-4);
    background: var(--negative-color);
    text-align: center;
    font-size: 1.2em;
}


//Article number chooser

.missing-icon {
    vertical-align: middle;
    transform: translateY(0.3em);
    opacity: 0;
}

.missing {
    .missing-icon {
        opacity: 1;
    }

    ::ng-deep {
        .mat-mdc-form-field {
            transform: translateY(8px);
        }

        //changed class of the input field
        .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--no-label {
            background: rgba(var(--negative-color), 0.15);
        }
    }
}

//Api-keys

.token {
    position: relative;

    .overlay {
        position: absolute;
        inset: 0;
        filter: blur(5px);
    }

    pre{
        width: 400px;
        white-space: pre-wrap;
        line-break: anywhere;
    }

    &.blurred{
        pre{
            filter: blur(4px);
        }
    }
}


//Reset password


.reset-password {
    background: var(--dark-background-color);
    height: 100vh;
    padding-top: 10%;

    .logo {
      margin-bottom: 10px;

      img {
        max-width: 100%;
      }
    }
  }

  //Reports

  .report-list {
    .reports {
      .report {
        a {
          color: black;
          font-size: 24px;

          mat-icon{
              vertical-align: sub;
              font-size: 28px;
              margin-right: 5px;
          }
        }
      }
    }
  }


  .sales-report {
    .refresh{
        transform: translateY(10px);
    }

    .top {
      padding: 10px;

      .search-mode {
        margin-bottom: 5px;
      }

      .search-mode,
      .display-mode {
        .option {
          display: inline;
          cursor: pointer;

          .mat-icon {
            transform: translateY(6px);
          }

          &.selected {
            font-weight: bold;
            cursor: normal;

            .mat-icon {
              font-weight: bold;
            }
          }
        }
      }

      .filter {
        display: inline-block;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 2px solid rgb(0 0 0 / 30%);

        .option {
          display: inline-block;

          i,
          .mat-icon {
            margin-right: 6px;
            font-size: 20px;
          }

          .mat-icon {
            font-size: 24px;
            transform: translateY(4px);
          }


          &.none {
            opacity: 0.3;
          }
        }
      }

      .display-mode {
        display: inline-block;

       //margin-right: 10px;
       //padding-right: 10px;
       //border-right: 2px solid rgba(0, 0, 0, 0.3);

        .option {
          opacity: 0.3;

          &.selected {
            opacity: 1;
          }
        }

        .mat-icon {
          margin-right: 5px;
        }
      }

      .print-button {
        display: inline-block;

        .mat-icon {
          transform: translateY(6px);
        }
      }
    }
  }


  .sales-report-table {
    .sales-table {
      font-size: 12px;
    }

    .vat-table {
      font-size: 12px;
    }

    .gift-card-table {
      font-size: 12px;
    }

    .total-table {
      font-size: 12px;
    }

    h2 {
      margin-left: 10px;
    }

    .dark-column {
      background: rgb(0 0 0 / 2%);
    }

    @media print {
      .sales-table,
      .vat-table,
      .gift-card-table {
        font-size: 3mm;

        tr td {
          padding: 1mm;
        }
      }
    }
  }


  .feedbacks {
    padding-right: 20px;
    padding-left: 20px;

    .summary{
      font-size: 1.3em;
    }

    .positive-summary {
      color: var(--green);

      .icon {
        color: var(--green);
      }
    }

    .negative-summary {
      color: var(--red);

      .icon {
        color: var(--red);
      }
    }

    .icon{
      vertical-align: middle;
      margin-right: 10px;
    }

  }

.journal-entry-dialog {
    width: 900px;
    max-width: 90vw;
    min-height: 400px;
    max-height: 90vh;
}

.red {
    color: var(--red);
}

.green {
    color: var(--green);
}

.yellow {
  color:var(--yellow);
}

.csv-container {
    text-align: left;
    position: absolute;
    right: 0;
    top: 150px;
    width: 71px;

    .download-button {
        position: static;

        mat-icon {
            font-size: 1.5em;
            width: 1em;
            height: 1em;
            transform: translateY(7px);
        }
    }
}

.label {
    font-weight: bold;
    color: var(--base-color-1);
}

.content {
    color: var(--base-color-1);
}


th,td{
    max-width: 300px;
    padding-left: 5px;
    padding-right: 5px;
}

.gift-card-report{
    padding-right: 20px;
    padding-left: 20px;
    font-size: 10px;
}

.top {
    padding: 10px;
}


.print-button {
  display: inline-block;
  font-size: 24px;
  transform: translateY(4px);
  margin-right: 6px;
}

.expired {
  background-color: rgba(var(--red), .15) !important;
}

.search-mode {
    display: inline-block;

    .option {
        display: inline;
        cursor: pointer;

        .mat-icon {
            transform: translateY(6px);
        }

        &.selected {
            font-weight: bold;
            cursor: normal;

            .mat-icon {
                font-weight: bold;
            }
        }
    }
}

.filter {
    display: inline-block;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 2px solid rgb(0 0 0 / 30%);

    .option {
        display: inline-block;

        .show-none,
        .show-green,
        .show-red {
            display: none;
        }

        &.none .show-none {
            display: inline-block;
        }

        &.green-text .show-green {
            display: inline-block;
        }

        &.red-text .show-red {
            display: inline-block;
        }

        i,
        .mat-icon {
            margin-right: 6px;
            font-size: 20px;
        }

        .mat-icon {
            font-size: 24px;
            transform: translateY(4px);
        }



        &.none {
            opacity: 0.3;
        }
    }
}


.NgxEditor, .html-editor {
    min-height: 240px;
    max-height: 240px;
    overflow-y: scroll;
}
.html-editor {
    background-color: var(--ngx-editor-background-color);
    color: var(--ngx-editor-text-color);
    border: none;
    box-shadow: var(--box-shadows);
    width: 100%;
    resize: none;
    border: none;
    box-shadow: var(--box-shadows);
    overflow-x: hidden;
    overflow-y: scroll;
    
    &:focus-visible {
        outline: none;
    }
}

i.disabled,
mat-icon.disabled {
    opacity: 0.6;
      color: var(--light-grey) !important;
    cursor: not-allowed;
}

i.neutral,
mat-icon.neutral {
  color: var(--neutral);
}

i.positive,
mat-icon.positive {
  color: var(--positive);
}

i.negative,
mat-icon.negative {
  color: var(--negative);
}