//Create the fontfile on fontello when new svgs need to be added. Store all svgs under /assets/svgs so we know which are in the font

@font-face {
    font-family: 'CustomIconFont';
    src: url('../assets/fonts/fontello.woff2') format('woff2'),
         url('../assets/fonts/fontello.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  

.custom-icon {
    font-family: 'CustomIconFont' !important;
    font-size: 20px;
    font-style: normal;
    &.icon-prison-island::before {
        content: "\e800"; 
      }
}

