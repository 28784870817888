@mixin resource-booking-background { 
    background-color: var(--calendar-resource-booking-event-background-color);
    background-image: linear-gradient(
      45deg,
      rgb(0 0 0 / 2%) 25%,
      rgb(255 255 255 / 0%) 25%,
      rgb(255 255 255 / 0%) 50%,
      rgb(0 0 0 / 2%) 50%,
      rgb(0 0 0 / 2%) 75%,
      rgb(255 255 255 / 0%) 75%,
      rgb(255 255 255 / 0%) 100%
    );
    background-size: 56.57px 56.57px;
}


@mixin blocked-resource-booking-background { 
  background-color: var(--calendar-blocked-resource-booking-event-background-color);
  background-image: linear-gradient(
    45deg,
    rgb(0 0 0 / 2%) 25%,
    rgb(255 255 255 / 0%) 25%,
    rgb(255 255 255 / 0%) 50%,
    rgb(0 0 0 / 2%) 50%,
    rgb(0 0 0 / 2%) 75%,
    rgb(255 255 255 / 0%) 75%,
    rgb(255 255 255 / 0%) 100%
  );
  background-size: 56.57px 56.57px;
}
