//$background-color:#17181d;
$background-color: #e0e0e0;
$dark-background-color: #1e1f24;
$wrapper-background-color: #fcfbfb;

//$wrapper-background-color:#1e1f24;
$header-background: #040023;
$header-color: $wrapper-background-color;

//$primary-color:#c1ff00;
$primary-color: #333;
$button-background: $primary-color;
$button-color: $background-color;
$calendar-background: #efefef;
$calendar-color: #333;
$calendar-selected-color: #76c5e0;
$calendar-event-background-color: #f0f0f0;
$calendar-resource-booking-event-background-color: #fdf2dc;
$calendar-blocked-resource-booking-event-background-color: #ffdfdf;
$calendar-resource-available-color: #93c37a;
$calendar-resource-has-bookings-color: #cde4af;
$calendar-resource-almost-full-color: #fffbb4;
$calendar-resource-full-color: #f0b3cd;


//$green:#9eff00;
$green: #5eaf39;

//$yellow:#e4ff00;
$yellow: #d3c436;
$red: #ff3087;
$purple: #ff6bd3;
$neutral: #76c5e0;
$grey: #333;
$light-grey: #a2a2a2;
$dark-red: #d20b0b;
$dark-green: #239e04;
$procommerca-red: #ce1e6b;



i.red,
mat-icon.red {
  color: $red;
}

i.green,
mat-icon.green {
  color: $green;
}

i.yellow,
mat-icon.yellow {
  color: $yellow;
}


.green-text {
  color: $green;
}

.red-text {
  color: $red;
}

.yellow-text {
  color: $yellow;
}

.neutral-text {
  color: $neutral;
}
